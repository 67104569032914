<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col lg="8" md="8" sm="12" xs="12">
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col>
                  <p class="subtitle-1 font-weight-bold">
                    Company Logo
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="3" md="3" sm="6" xs="6">
                  <span class="subtitle-2">
                    Upload new company logo image
                  </span>
                </v-col>
                <v-col cols="12" lg="4" md="4" sm="6" xs="6"
                  ><p class="error subtitle-2 font-weight-bold" v-if="error">
                    {{ error }}
                  </p>
                  <v-file-input
                    label="Upload new company logo image"
                    v-model="file"
                    outlined
                    dense
                    small-chips
                    show-size
                  >
                  </v-file-input>
                </v-col>
                <v-col>
                  <v-btn
                    :color="getColorScheme.darkColor"
                    dark
                    small
                    raised
                    v-on:click="uploadLogoImage"
                    >Upload
                  </v-btn>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12" lg="3" md="3" sm="6" xs="6">
                  <span class="subtitle-2">
                    Current company logo
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  lg="5"
                  md="5"
                  sm="6"
                  xs="6"
                  :style="`background-color:#E0E0E0`"
                  class="d-flex align-center justify-center"
                  v-if="getCompanyLogo.logoImage != null"
                >
                  <v-img
                    :src="getCompanyLogo.logoImage.url"
                    max-width="18vh"
                    max-height="6vh"
                    contain
                  ></v-img>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "comapny-logo",
  data() {
    return {
      file: null,
      fileId: "",
      error: "",
    };
  },
  created() {
    this.refId = this.getCompanyLogo._id;
  },
  computed: {
    ...mapGetters(["getColorScheme", "getCompanyLogo"]),
  },
  methods: {
    ...mapActions(["uploadCompanyLogoAction"]),

    async uploadLogoImage() {
      if (this.file != null && this.file.size > 1024 * 1024 * 5) {
        this.error = "File too big (> 5MB)";
        return;
      } else {
        this.error = "";
      }
      let dataObj = { refId: this.refId, file: this.file };
      await this.uploadCompanyLogoAction(dataObj);
      this.file = null;
    },
  },
};
</script>
