<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col lg="8" md="8" sm="12" xs="12">
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col>
                  <p class="subtitle-1 font-weight-bold">
                    Color Scheme
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="pr-3">
                  <span class="subtitle-2 font-weight-bold">
                    Dark Color
                  </span>
                  <p class="caption">
                    Button Color, Pop-up window title background color,
                    Date/Time picker color, Checkbox/Toggle element selection
                    color
                  </p>
                </v-col>
                <v-col cols="12" lg="8" md="8" sm="12" xs="12">
                  <span class="subtitle-2 font-weight-bold">
                    Light Color
                  </span>
                  <p class="caption">
                    Expansion panel background color
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="pr-3">
                  <v-color-picker v-model="darkColor" class="ma-2" />
                </v-col>
                <v-col cols="12" lg="8" md="8" sm="12" xs="12">
                  <v-color-picker v-model="lightColor" class="ma-2" />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="pt-7">
                  <span class="subtitle-2 font-weight-bold">
                    Header Gradient colors
                  </span>
                  <p class="caption">
                    Three header gradient colors - left to right - light to dark
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="pr-3">
                  <v-color-picker v-model="headerLight" class="ma-2" />
                </v-col>
                <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="pr-3">
                  <v-color-picker v-model="headerDark" class="ma-2" />
                </v-col>
                <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="pr-3">
                  <v-color-picker v-model="headerDarkest" class="ma-2" />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="pt-7">
                  <span class="subtitle-2 font-weight-bold">
                    Footer Background Color
                  </span>
                  <p class="caption">
                    The background color of footer
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="pr-3">
                  <v-color-picker v-model="footerBgColor" class="ma-2" />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :color="getColorScheme.darkColor"
                      small
                      raised
                      dark
                      @click="editRecord"
                      >Save
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "color-Scheme",
  data() {
    return {
      darkColor: "#00695c", //teal darken-3
      lightColor: "#e0f2f1", //teal lighten-5
      headerLight: "#FFAF7B",
      headerDark: "#D76D77",
      headerDarkest: "#3A1C71",
      footerBgColor: "#00695c",
    };
  },
  async created() {
    await this.getColorSchemeAction();
    this.darkColor = this.getColorScheme.darkColor;
    this.lightColor = this.getColorScheme.lightColor;
    this.headerLight = this.getColorScheme.headerLight;
    this.headerDark = this.getColorScheme.headerDark;
    this.headerDarkest = this.getColorScheme.headerDarkest;
    this.footerBgColor = this.getColorScheme.footerBgColor;
  },
  computed: {
    ...mapGetters(["getColorScheme"]),
  },
  methods: {
    ...mapActions(["getColorSchemeAction", "editColorSchemeAction"]),
    async editRecord() {
      await this.editColorSchemeAction({
        darkColor: this.darkColor,
        lightColor: this.lightColor,
        headerLight: this.headerLight,
        headerDark: this.headerDark,
        headerDarkest: this.headerDarkest,
        footerBgColor: this.footerBgColor,
      });
    },
  },
};
</script>
