<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col lg="8" md="8" sm="12" xs="12">
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col>
                  <p class="subtitle-1 font-weight-bold">
                    System-wide API Settings
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="3" md="3" sm="6" xs="6">
                  <span class="subtitle-2">
                    Google Maps API Key:
                  </span>
                </v-col>
                <v-col cols="12" lg="8" md="8" sm="6" xs="6"
                  >
                  <v-text-field
                  v-model="getApiSettings.googleMapKey"
                  label="Google Map Key"
                  dense
                  outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="3" md="3" sm="6" xs="6">
                  <span class="subtitle-2">
                    SendGrid API Key:
                  </span>
                </v-col>
                <v-col cols="12" lg="8" md="8" sm="6" xs="6"
                  >
                  <v-text-field
                  v-model="getApiSettings.sendGridKey"
                  label="SendGrid Key"
                  dense
                  outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="3" md="3" sm="6" xs="6">
                  <span class="subtitle-2">
                    SendGrid From:
                  </span>
                </v-col>
                <v-col cols="12" lg="8" md="8" sm="6" xs="6"
                  >
                  <v-text-field
                  v-model="getApiSettings.sendGridFrom"
                  label="SendGrid Email From"
                  dense
                  outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="3" md="3" sm="6" xs="6">
                  <span class="subtitle-2">
                    SendGrid Reply To:
                  </span>
                </v-col>
                <v-col cols="12" lg="8" md="8" sm="6" xs="6"
                  >
                  <v-text-field
                  v-model="getApiSettings.sendGridReplyTo"
                  label="SendGrid Reply To"
                  dense
                  outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="3" md="3" sm="6" xs="6">
                  <span class="subtitle-2">
                    Cloudinary Name:
                  </span>
                </v-col>
                <v-col cols="12" lg="8" md="8" sm="6" xs="6"
                  >
                  <v-text-field
                  v-model="getApiSettings.cloudinaryName"
                  label="Cloudinary Name"
                  dense
                  outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="3" md="3" sm="6" xs="6">
                  <span class="subtitle-2">
                    Cloudinary API Key:
                  </span>
                </v-col>
                <v-col cols="12" lg="8" md="8" sm="6" xs="6"
                  >
                  <v-text-field
                  v-model="getApiSettings.cloudinaryKey"
                  label="Cloudinary API Key"
                  dense
                  outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="3" md="3" sm="6" xs="6">
                  <span class="subtitle-2">
                    Cloudinary Secret:
                  </span>
                </v-col>
                <v-col cols="12" lg="8" md="8" sm="6" xs="6"
                  >
                  <v-text-field
                  v-model="getApiSettings.cloudinarySecret"
                  label="Cloudinary Secret"
                  dense
                  outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :color="getColorScheme.darkColor"
                      small
                      raised
                      dark
                      @click="editRecord"
                      >Save
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "api-settings",
  async created() {
    await this.getApiSettingsAction();
  },
  computed: {
    ...mapGetters(["getApiSettings", "getColorScheme"]),
  },
  methods: {
    ...mapActions(["getApiSettingsAction", "editApiSettingsAction"]),
    
    async editRecord() {
      await this.editApiSettingsAction(this.getApiSettings);
    },
  },
};
</script>
