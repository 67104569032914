<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col lg="8" md="8" sm="12" xs="12">
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col>
                  <p class="subtitle-1 font-weight-bold">
                    Module Role Permissions
                  </p>
                </v-col>
                <v-col>
                  <span class="float-right">
                    <v-btn
                      :color="getColorScheme.darkColor"
                      small
                      dark
                      raised
                      @click="dialog = true"
                    >
                      <v-icon class="mr-1" small>
                        mdi-plus
                      </v-icon>
                      Module Role Permission
                    </v-btn>
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-data-table
                    :headers="headers"
                    :items="getModuleRolePermissions"
                    class="elevation-1"
                    dense
                    :footer-props="{
                      'items-per-page-options': [20, 30, 40, 50, 100],
                    }"
                    :items-per-page="50"
                  >
                    <template v-slot:[`item.roles`]="{ item }">
                      <span class="caption">{{ item.role.name }}</span>
                    </template>
                    <template v-slot:[`item.modules`]="{ item }">
                      <span class="caption">
                        {{ item.module.name }}
                      </span>
                    </template>
                    <template v-slot:[`item.isVisible`]="{ item }">
                      <v-row no-gutters>
                        <v-col class="d-flex align-center justify-center">
                          <v-checkbox
                            dense
                            hide-details
                            class="mt-0 mb-1"
                            :color="getColorScheme.darkColor"
                            :value="item.isVisible"
                            v-model="item.isVisible"
                            @click.native="isVisibleCheckboxClicked(item)"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :color="getColorScheme.darkColor"
                      small
                      raised
                      dark
                      @click="updateAllPermissions"
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" persistent width="40vh">
      <v-card>
        <v-card-title
          class="title white--text"
          :style="`background-color:${getColorScheme.darkColor};`"
          >New Module Role Permission
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row no-gutters v-if="errors.length">
              <v-col>
                <strong>Please correct the following error(s):</strong>
                <ul class="red--text">
                  <li v-for="(error, i) in errors" :key="i">{{ error }}</li>
                </ul>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="pt-6">
                <v-combobox
                  v-model="rolePermissions.role"
                  :items="getUserRoleList.roles"
                  item-value="name"
                  item-text="name"
                  label="User Role"
                  outlined
                  dense
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-combobox
                  v-model="rolePermissions.module"
                  :items="getModules"
                  item-value="name"
                  item-text="name"
                  label="Modules"
                  outlined
                  dense
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row no-gutters align="center">
              <v-col cols="2" class="caption">
                <span class="caption">isVisible</span>
              </v-col>
              <v-col>
                <v-checkbox
                  dense
                  hide-details
                  :color="getColorScheme.darkColor"
                  class="mt-0 mb-2"
                  :value="rolePermissions.isVisible"
                  v-model="rolePermissions.isVisible"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue-grey" small raised dark @click="cancel"
                    >Cancel
                  </v-btn>
                  <v-btn
                    :color="getColorScheme.darkColor"
                    small
                    raised
                    dark
                    @click="saveRecord"
                    >Submit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "module-role-permissions",
  data() {
    return {
      rolePermissions: {},
      dialog: false,
      errors: [],
      headers: [
        {
          text: "Role",
          value: "roles",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Module",
          value: "modules",
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "isVisible",
          value: "isVisible",
          width: "50%",
          align: "center",
          class: "grey darken-3 white--text",
          mobile: true,
        },
      ],
    };
  },
  async created() {
    await this.getModuleRolePermissionAction();
    await this.getUserRoleListAction();
    await this.getModuleAction();
    this.resetFields();
  },
  computed: {
    ...mapGetters([
      "getModuleRolePermissions",
      "getModules",
      "getUserRoleList",
      "getColorScheme",
    ]),
  },
  methods: {
    ...mapActions([
      "getModuleRolePermissionAction",
      "getModuleAction",
      "getUserRoleListAction",
      "addModuleRolePermissionAction",
      "updateAllPermissionsAction",
    ]),
    async updateAllPermissions() {
      await this.updateAllPermissionsAction(this.getModuleRolePermissions);
    },
    isVisibleCheckboxClicked: function(item) {
      if (item.isVisible == null) {
        item.isVisible = false;
      }
    },
    cancel() {
      this.dialog = false;
      this.resetFields();
    },
    async saveRecord() {
      if (!this.isFormValid()) {
        return;
      }
      await this.addModuleRolePermissionAction(this.rolePermissions);
      this.dialog = false;
      this.resetFields();
    },
    isFormValid() {
      let isValid = true;
      this.errors = [];
      if (this.rolePermissions.role == "") {
        this.errors.push("Role is required.");
      }
      if (this.rolePermissions.module == "") {
        this.errors.push("Module is required.");
      }
      if (
        this.rolePermissions.role != "" &&
        this.rolePermissions.module != ""
      ) {
        let alreadyExits = this.getModuleRolePermissions.find(
          (el) =>
            el.role.name == this.rolePermissions.role.name &&
            el.module.name == this.rolePermissions.module.name
        );
        if (alreadyExits != undefined) {
          this.errors.push("Role & Module combination exists.");
        }
      }

      if (this.errors.length > 0) {
        isValid = false;
      }
      return isValid;
    },
    resetFields() {
      this.rolePermissions = {
        role: "",
        module: "",
        isVisible: false,
      };
      this.errors = [];
    },
  },
};
</script>
