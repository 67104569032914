<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col lg="8" md="8" sm="12" xs="12">
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col>
                  <p class="subtitle-1 font-weight-bold">
                    Vehicle Makes
                  </p>
                </v-col>
                <v-col>
                  <span class="float-right">
                    <v-btn
                      :color="getColorScheme.darkColor"
                      small
                      dark
                      raised
                      @click="dialog = true"
                    >
                      <v-icon class="mr-1" small>
                        mdi-plus
                      </v-icon>
                      Vehicle Make
                    </v-btn>
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-data-table
                    :headers="headers"
                    :items="getVehicleMakes"
                    class="elevation-1"
                    dense
                  >
                    <template v-slot:[`item.name`]="{ item }">
                      <span class="caption">{{ item.name }}</span>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                      <v-row no-gutters align="center">
                        <v-col class="caption" align="left">
                          {{ item.description }}
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <v-row no-gutters align="center">
                        <v-col align="center">
                          <v-btn icon>
                            <v-icon
                              color="teal"
                              small
                              @click.native="editRecord($event, item)"
                            >
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                          <v-btn icon>
                            <v-icon
                              color="pink"
                              small
                              @click.native="
                                confirmDeleteRecord($event, item.id)
                              "
                              >mdi-delete
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" persistent width="40vh">
      <v-card>
        <v-card-title
          v-if="isEdit"
          class="title white--text"
          :style="`background-color:${getColorScheme.darkColor};`"
          >Edit Vehicle Make
        </v-card-title>
        <v-card-title
          v-else
          class="title white--text"
          :style="`background-color:${getColorScheme.darkColor};`"
          >New Vehicle Make
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row no-gutters v-if="errors.length">
              <v-col>
                <strong>Please correct the following error(s):</strong>
                <ul class="red--text">
                  <li v-for="(error, i) in errors" :key="i">{{ error }}</li>
                </ul>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="pt-6">
                <v-text-field
                  v-model="vehicleMake.name"
                  label="Name"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-text-field
                  v-model="vehicleMake.description"
                  label="Description"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue-grey" small raised dark @click="cancel">
                    Cancel
                  </v-btn>
                  <v-btn
                    v-if="isEdit"
                    :color="getColorScheme.darkColor"
                    small
                    raised
                    dark
                    @click="editRecordSave"
                    >Save
                  </v-btn>
                  <v-btn
                    v-else
                    :color="getColorScheme.darkColor"
                    small
                    raised
                    dark
                    @click="saveRecord"
                    >Submit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteConfirm" persistent max-width="425">
      <v-card>
        <v-card-title class="headline">Delete Vehicle Make?</v-card-title>
        <v-card-text>
          Are you sure you want to delete this vehicle make?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-grey"
            dark
            small
            raised
            @click="dialogDeleteConfirm = false"
            >Cancel
          </v-btn>
          <v-btn
            :color="getColorScheme.darkColor"
            dark
            small
            raised
            @click="deleteRecord"
            >Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "vehicle-makes",
  data() {
    return {
      vehicleMake: {},
      dialog: false,
      dialogDeleteConfirm: false,
      deleteId: null,
      isEdit: false,
      errors: [],
      headers: [
        {
          text: "Name",
          value: "name",
          width: "35%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Description",
          value: "description",
          width: "45%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Action(s)",
          value: "action",
          width: "20%",
          align: "center",
          class: "grey darken-3 white--text",
          mobile: true,
        },
      ],
    };
  },
  async created() {
    await this.getVehicleMakesAction();
    this.resetFields();
  },
  computed: {
    ...mapGetters(["getVehicleMakes", "getColorScheme"]),
  },
  methods: {
    ...mapActions([
      "getVehicleMakesAction",
      "addVehicleMakeAction",
      "editVehicleMakeAction",
      "deleteVehicleMakeAction",
    ]),
    editRecord(e, selectedObj) {
      e.stopPropagation();
      this.isEdit = true;
      this.dialog = true;
      this.vehicleMake = {
        id: selectedObj.id,
        name: selectedObj.name,
        description: selectedObj.description,
      };
    },
    async editRecordSave() {
      if (!this.isFormValid()) {
        return;
      }
      await this.editVehicleMakeAction(this.vehicleMake);
      this.dialog = false;
      this.isEdit = false;
      this.resetFields();
    },
    confirmDeleteRecord: function(e, deleteId) {
      e.stopPropagation();
      this.dialogDeleteConfirm = true;
      this.deleteId = deleteId;
    },
    async deleteRecord() {
      await this.deleteVehicleMakeAction(this.deleteId);
      this.dialogDeleteConfirm = false;
    },
    cancel() {
      this.dialog = false;
      this.isEdit = false;
      this.resetFields();
    },
    async saveRecord() {
      if (!this.isFormValid()) {
        return;
      }
      await this.addVehicleMakeAction(this.vehicleMake);
      this.dialog = false;
      this.resetFields();
    },
    isFormValid() {
      let isValid = true;
      this.errors = [];
      if (this.vehicleMake.name === "" || this.vehicleMake.name.trim() === "") {
        this.errors.push("Name is required.");
      }
      if (
        this.vehicleMake.description === "" ||
        this.vehicleMake.description.trim() === ""
      ) {
        this.errors.push("Description is required.");
      }

      if (this.errors.length > 0) {
        isValid = false;
      }
      return isValid;
    },
    resetFields() {
      this.vehicleMake = {
        name: "",
        description: "",
      };
      this.errors = [];
    },
  },
};
</script>
