<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col lg="8" md="8" sm="12" xs="12">
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col>
                  <p class="subtitle-1 font-weight-bold">
                    Parking Violation Types
                  </p>
                </v-col>
                <v-col>
                  <span class="float-right">
                    <v-btn
                      :color="getColorScheme.darkColor"
                      small
                      dark
                      raised
                      @click="dialog = true"
                    >
                      <v-icon class="mr-1" small>
                        mdi-plus
                      </v-icon>
                      Parking Violation Type
                    </v-btn>
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-data-table
                    :headers="headers"
                    :items="getViolationTypes"
                    class="elevation-1"
                    dense
                  >
                    <template v-slot:[`item.type`]="{ item }">
                      <span class="caption">{{ item.type }}</span>
                    </template>
                    <template v-slot:[`item.bylawNumber`]="{ item }">
                      <span class="caption">
                        {{ item.bylawNumber }}
                      </span>
                    </template>
                    <template v-slot:[`item.earlyPmtAmt`]="{ item }">
                      <span class="caption">{{ "$" + item.earlyPmtAmt }}</span>
                    </template>
                    <template v-slot:[`item.setFineAmt`]="{ item }">
                      <span class="caption">{{ "$" + item.setFineAmt }}</span>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <v-row no-gutters align="center">
                        <v-col align="center">
                          <v-btn icon>
                            <v-icon
                              color="teal"
                              small
                              @click.native="editRecord($event, item)"
                            >
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                          <v-btn icon>
                            <v-icon
                              color="pink"
                              small
                              @click.native="
                                confirmDeleteRecord($event, item.id)
                              "
                              >mdi-delete
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" persistent width="40vh">
      <v-card>
        <v-card-title
          v-if="isEdit"
          class="title white--text"
          :style="`background-color:${getColorScheme.darkColor};`"
          >Edit Parking Violation Type
        </v-card-title>
        <v-card-title
          v-else
          class="title white--text"
          :style="`background-color:${getColorScheme.darkColor};`"
          >New Parking Violation Type
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row no-gutters v-if="errors.length">
              <v-col>
                <strong>Please correct the following error(s):</strong>
                <ul class="red--text">
                  <li v-for="(error, i) in errors" :key="i">{{ error }}</li>
                </ul>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="pt-6">
                <v-text-field
                  v-model="violationType.type"
                  label="Type"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-text-field
                  v-model="violationType.bylawNumber"
                  label="Bylaw Number"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-text-field
                  v-model="violationType.earlyPmtAmt"
                  label="Early Payment Amount"
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-text-field
                  v-model="violationType.setFineAmt"
                  label="Set Fine Amount"
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue-grey" small raised dark @click="cancel"
                    >Cancel
                  </v-btn>
                  <v-btn
                    v-if="isEdit"
                    :color="getColorScheme.darkColor"
                    small
                    raised
                    dark
                    @click="editRecordSave"
                    >Save
                  </v-btn>
                  <v-btn
                    v-else
                    :color="getColorScheme.darkColor"
                    small
                    raised
                    dark
                    @click="saveRecord"
                    >Submit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteConfirm" persistent max-width="425">
      <v-card>
        <v-card-title class="headline">
          Delete Parking Violation Type?
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete this parking violation type?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-grey"
            dark
            small
            raised
            @click="dialogDeleteConfirm = false"
            >Cancel
          </v-btn>
          <v-btn
            :color="getColorScheme.darkColor"
            dark
            small
            raised
            @click="deleteRecord"
            >Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "vehicle-types",
  data() {
    return {
      violationType: {},
      dialog: false,
      dialogDeleteConfirm: false,
      deleteId: null,
      isEdit: false,
      errors: [],
      headers: [
        {
          text: "Type",
          value: "type",
          width: "35%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "ByLaw Number",
          value: "bylawNumber",
          width: "15%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Early Payment Amount",
          value: "earlyPmtAmt",
          width: "20%",
          align: "center",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Set Fine Amount",
          value: "setFineAmt",
          width: "20%",
          align: "center",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Action(s)",
          value: "action",
          width: "10%",
          align: "center",
          class: "grey darken-3 white--text",
          mobile: true,
        },
      ],
    };
  },
  async created() {
    await this.getViolationTypesAction();
    this.resetFields();
  },
  computed: {
    ...mapGetters(["getViolationTypes", "getColorScheme"]),
  },
  methods: {
    ...mapActions([
      "getViolationTypesAction",
      "addViolationTypeAction",
      "editViolationTypeAction",
      "deleteViolationTypeAction",
    ]),
    editRecord(e, selectedObj) {
      e.stopPropagation();
      this.isEdit = true;
      this.dialog = true;
      this.violationType = {
        id: selectedObj.id,
        type: selectedObj.type,
        bylawNumber: selectedObj.bylawNumber,
        earlyPmtAmt: selectedObj.earlyPmtAmt,
        setFineAmt: selectedObj.setFineAmt,
      };
    },
    async editRecordSave() {
      if (!this.isFormValid()) {
        return;
      }
      await this.editViolationTypeAction(this.violationType);
      this.dialog = false;
      this.isEdit = false;
      this.resetFields();
    },
    confirmDeleteRecord: function(e, deleteId) {
      e.stopPropagation();
      this.dialogDeleteConfirm = true;
      this.deleteId = deleteId;
    },
    async deleteRecord() {
      await this.deleteViolationTypeAction(this.deleteId);
      this.dialogDeleteConfirm = false;
    },
    cancel() {
      this.dialog = false;
      this.isEdit = false;
      this.resetFields();
    },
    async saveRecord() {
      if (!this.isFormValid()) {
        return;
      }
      await this.addViolationTypeAction(this.violationType);
      this.dialog = false;
      this.resetFields();
    },
    isFormValid() {
      let isValid = true;
      this.errors = [];
      if (
        this.violationType.type === "" ||
        this.violationType.type.trim() === ""
      ) {
        this.errors.push("Type is required.");
      }
      if (
        this.violationType.bylawNumber === "" ||
        this.violationType.bylawNumber.trim() === ""
      ) {
        this.errors.push("Bylaw Number is required.");
      }
      if (this.violationType.earlyPmtAmt === "") {
        this.errors.push("Early Payment Amount is required.");
      }
      if (this.violationType.setFineAmt === "") {
        this.errors.push("Set Fine Amount is required.");
      }

      if (this.errors.length > 0) {
        isValid = false;
      }
      return isValid;
    },
    resetFields() {
      this.violationType = {
        type: "",
        bylawNumber: "",
        earlyPmtAmt: "",
        setFineAmt: "",
      };
      this.errors = [];
    },
  },
};
</script>
