<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col lg="4" md="4" sm="12" xs="12">
          <v-card
            max-width="550"
            height="40vh"
            :color="getColorScheme.lightColor"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <p class="subtitle-1 font-weight-bold text-uppercase">
                    Parking Violations
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-btn text small @click="showViolationTypes">
                    Parking Violation Types
                  </v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-btn text small @click="showVehicleMakes">
                    Vehicle Makes
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4" md="4" sm="12" xs="12">
          <v-card
            max-width="550"
            height="40vh"
            :color="getColorScheme.lightColor"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <p class="subtitle-1 font-weight-bold text-uppercase">
                    Customer Complaints
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-btn text small @click="showComplaintCategories">
                    Complaint Categories
                  </v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-btn text small @click="showComplaintTypes">
                    Complaint Types
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4" md="4" sm="12" xs="12">
          <v-card
            max-width="550"
            height="40vh"
            :color="getColorScheme.lightColor"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <p class="subtitle-1 font-weight-bold text-uppercase">
                    Yard Orders
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-btn text small @click="showYORequirements">
                    Requirements
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="4" md="4" sm="12" xs="12">
          <v-card
            max-width="550"
            height="40vh"
            :color="getColorScheme.lightColor"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <p class="subtitle-1 font-weight-bold text-uppercase">
                    System
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-btn text small @click="showModuleRolePermissions">
                    Role Permissions
                  </v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-btn text small @click="showCompanyLogo">
                    Company Logo
                  </v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-btn text small @click="showColorScheme">
                    Color Scheme
                  </v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-btn text small @click="showApiSettings">
                    API Settings
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as constants from "../../shared/constants";

export default {
  name: "setting-options",
  data() {
    return {
      clrBtn: constants.CLR_BTN,
    };
  },
  computed: {
    ...mapGetters(["getColorScheme"]),
  },
  methods: {
    //Parking Violations
    showViolationTypes() {
      this.$router.push({ name: "violationTypes" });
    },
    showVehicleMakes() {
      this.$router.push({ name: "vehicleMakes" });
    },
    //Complaints
    showComplaintCategories() {
      this.$router.push({ name: "complaintCategories" });
    },
    showComplaintTypes() {
      this.$router.push({ name: "complaintTypes" });
    },
    //Yard Orders
    showYORequirements() {
      this.$router.push({ name: "yoRequirements" });
    },
    //System
    showModuleRolePermissions() {
      this.$router.push({ name: "moduleRolePermissions" });
    },
    showCompanyLogo() {
      this.$router.push({ name: "companyLogo" });
    },
    showColorScheme() {
      this.$router.push({ name: "colorScheme" });
    },
    showApiSettings() {
      this.$router.push({ name: "apiSettings" });
    },
  },
};
</script>
